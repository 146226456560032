
/* eslint-disable */
import {
  chooseEnterprise,
  getUserEnterpriseList,
  LoginCode,
  sendVerificationCode,
  userLogin,
  userNoLogin,
  getUserAgres,
  saveUserAgres
} from "@/api/user";
import cookies from "@/utils/cookies";
import { getStore, setStore } from "@/utils/storage";
import QRCode from "qrcode";
import { getCodeImg, getIP } from "~/api/system";
import { getRsaCode } from "@/utils/auth";

export default {
  layout: "blank",
  head() {
    return {
      title: this.service.websiteTitle || "",
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: this.service.websiteKeyword,
        },
        {
          hid: "description",
          name: "description",
          content: this.service.websiteDesc,
        },
      ],
    };
  },
  data() {
    return {
      tabKey: "3",
      checkbox: 0,
      service: this.$store.state.webInfo,
      opts: {
        imgCode: "",
        loginType: "password",
        codeUrl: "",
        time: null,
        count: 0,
        showPhoneErrorMessage: "",
        showCodeErrorMessage: "",
      },
      map: {
        password: "",
        verificationCode: "",
        clientId: this.$store.state.clientData.id,
        clientType: 1,
        loginName: "",
        imgVerificationCode: "",
        imgVerificationCodeToken: "",
        websiteCopyright: "",
        websiteIcpNo: "",
        websitePrnNo: ""
      },
      ctrl: {
        codeErrorLoading: false,
        phoneErrorLoading: true,
      },
      ipInfo: {},
      clientData: this.$store.state.clientData,
      eid: "",
      errorCount: 0,
      imageVerification: this.$store.state.imageVerification,
      // 添加切换语言按钮
      languageName: '',
      languageList: [
          {
              type: 'zh',
              name: ''
          },
          {
              type: 'zhhk',
              name: ''
          },
          {
              type: 'en',
              name: ''
          },
          {
              type: 'ja',
              name: ''
          }
      ],
      agressDialogVisible: false, // 显示协议对话框
      vxAgreementDisplayrules: 1, // 1 始终弹出 2 同意后不弹出
      reid: '',
      loadColorFinish: false, // 判断页面替换配置颜色完成后再显示
      loading: false,
      vxEntWeblogoImg: '~/assets/login_left.png'
    };
  },
  created() {
    // 添加切换语言按钮
    this.languageList.forEach((e) => {
        if (e.type === (cookies.getInClient('yqx_web_lang') || 'zh')) {
            this.languageName = e.name;
        }
    });
  },
  watch: {
    errorCount(val) {
      if (val >= 2) {
        this.$store.dispatch("toggleImageVerification", true);
        this.imageVerification = true;
      } else {
        this.imageVerification = false;
      }
    },
    imageVerification(val) {
      if (val) {
        this.getImgCode();
      }
    },
  },
  // 添加切换语言按钮
  computed: {
    rules() {
      const checkPhone = (rule, value, callback) => {
        if (value) {
          if(this.tabKey === '3') {
            if (!/^(0|86|17951)?1[0-9]{10}$/.test(this.map.loginName.trim())) {
              return callback(new Error(this.$t('login.pleaseInputSuccessPhone')))
            } else {
              callback()
            }
          }else{
            callback()
          }
        } else {
          if(this.tabKey === '3') {
            return callback(new Error(this.$t('login.pleaseInputSuccessPhone')))
          }else{
            return  callback(new Error(this.$t('login.pleaseInputNo')))
          }
        }
      }
      return {
        loginName: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        password: [{ required: true, message: this.$t('login.pleaseInputPassword'), trigger: 'blur' }],
        verificationCode: [{ required: true, message: this.$t('login.pleaseInputCode'), trigger: 'blur' }],
        imgVerificationCode: [{ required: true, message: this.$t('login.pleaseInputCode'), trigger: 'blur' }]
      };
    },
    language() {
      console.log(cookies.getInClient('yqx_web_lang'), 'lang')
      return cookies.getInClient('yqx_web_lang') !== 'en' ? this.$t('cn') : this.$t('en')
    },
  },
  async asyncData(context) {
    let host = "";
    if (context.req && context.req.headers) {
      host = context.req.headers.host;
    }
    if (process.client) {
      host = document.location.host;
    }
    // host = 'lms-demo-ly.learningpark.cn'; // TODO 测试完再删除
    const webInfo = await context.store.dispatch("GET_WEBINFO", {
      domain: host,
    });
  
    return {
      service: webInfo,
      vxEntWeblogoImg: webInfo.vxEntWeblogoImg
    };
    // 设置主题颜色
    // this.setThemeColor(webInfo);
  },
  mounted() {
    this.$i18n.locale = cookies.getInClient("yqx_web_lang") || "zh";
    this.translate()
    getIP().then((res) => {
      this.ipInfo = res;
      setStore("ipInfo", res);
    });
    this.errorCount = parseInt(getStore("loginErrorCount")) || 0;
    if (this.errorCount >= 2) {
      this.$store.dispatch("toggleImageVerification", true);
      this.getImgCode();
    }
    this.getQrcode(this.$t("login.DingTalk"));
    let hostName = window.location.host.split(".")[0];
    if (window.location.hostname === "qipei.polyv.net") {
      hostName = "1506897635905396737";
    }
    console.log("hostName", hostName);
    // if (Number(hostName)) {
    this.$store
      .dispatch("GET_WEBINFO", { domain: window.location.host })
      // .dispatch("GET_WEBINFO", { domain: 'lms-demo-ly.learningpark.cn' })
      .then((res) => {
        if (res.eid) {
          this.eid = res.eid;  
        }
        this.tabKey = res.vxLoginpage !== 2 ? '3' : '4';
        this.vxAgreementDisplayrules = !res.vxAgreementDisplayrules ? 1 : res.vxAgreementDisplayrules
        this.getBackgroundCss();
        setStore("webIcon", res.websiteIco);
        setStore("vxPagecss", res.vxPagecss);
        setStore("websiteLogo", res.websiteLogo);
        setStore("vxPagetheme", res.vxPagetheme || '#409EFF');
        let cssStr = getStore('vxPagecss')
        if (cssStr && cssStr.includes('.defaultLang { zh-cn }')) {
          this.languageFn({ type: 'zh',name: '中文' })
        } else if (cssStr && cssStr.includes('.defaultLang { zh-hk }')) {
          this.languageFn({ type: 'zhhk',name: '繁體中文' })
        } else if (cssStr && cssStr.includes('.defaultLang { en }')) {
          this.languageFn({ type: 'en',name: 'English' })
        } else if (cssStr && cssStr.includes('.defaultLang { ja }')) {
          this.languageFn({ type: 'ja',name: '日本語' })
        }
      });
    this.setThemeColor(this.service);
  },
  methods: {
    // 翻译切换多语言的名称
    translate() {
      this.languageList[0].name = this.$t('VX_header.languageTypeEnum.zh')
      this.languageList[1].name = this.$t('VX_header.languageTypeEnum.zhhk')
      this.languageList[2].name = this.$t('VX_header.languageTypeEnum.en')
      this.languageList[3].name = this.$t('VX_header.languageTypeEnum.ja')
    },
    // 添加切换语言按钮
    languageFn(item) {
      const lang = item.type;
      this.$store.commit('SET_LANG', lang)
      this.$i18n.locale = lang;
      this.translate()
      this.languageName = this.languageList.filter(ite => ite.type === item.type)[0].name
      this.$nextTick(() => {
        this.$refs.login.clearValidate();
      })
    },
    getImgCode() {
      getCodeImg().then((res) => {
        this.map.imgVerificationCodeToken = res.token;
        this.opts.imgCode = res.img;
      });
    },
    // 获取消息权限接口列表
    setpermission() {
      localStorage.setItem('permissionList', JSON.stringify([{route: '/home', url: '/system/auth/pc/portal/list'}]))
    },
    handleLoginNo() {
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          // if (!this.checkbox) {
          //   this.$message.error(this.$t("pleaseAgressLog"));
          //   return;
          // }
          this.setpermission();
          const params = JSON.parse(JSON.stringify(this.map));
          params.clientId = this.clientData.id;
          //params.city = this.ipInfo.city
          //params.province = this.ipInfo.pro
          //params.loginIp = this.ipInfo.ip
          params.os = this.getOsInfo().name + ":" + this.getOsInfo().version;
          params.browser =
            this.getBrowserInfo().name + ":" + this.getBrowserInfo().version;
          if (window.location.href.indexOf("localhost") > -1) {
            params.websiteDomain = "lms-demo-ly.learningpark.cn";
          } else {
            params.websiteDomain = window.location.host;
          }

          params.loginEncryption = await getRsaCode(params.password)
          delete params.password
          this.loading = true;
          userNoLogin(params)
            .then((res) => {
              if (res) {
                setStore("loginErrorCount", 0);
                this.errorCount = 0;
                this.$store.dispatch("toggleImageVerification", false);
                console.log(res);
                if (res) {
                  if (!cookies.getInClient("yqx_web_lang")) {
                    this.$store.commit("SET_LANG", "zh");
                  }
                  this.$store.commit("SET_TOKEN", res.token);
                  this.toOrg(res.eid); 
                } else {
                  this.loading = false;
                  this.getImgCode();
                  this.$message.error(res.msg);
                }
              }
            }).catch(() => {
              this.loading = false;
              this.errorCount++;
              setStore("loginErrorCount", this.errorCount);
              this.getImgCode();
            });
        }
      });
    },
    handleLogin() {
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          // if (!this.checkbox) {
          //   this.$message.error(this.$t("pleaseAgressLog"));
          //   return;
          // }
          this.setpermission();
          const params = JSON.parse(JSON.stringify(this.map));
          params.clientId = this.clientData.id;
          //params.city = this.ipInfo.city
          //params.province = this.ipInfo.pro
          //params.loginIp = this.ipInfo.ip
          params.os = this.getOsInfo().name + ":" + this.getOsInfo().version;
          params.browser =
            this.getBrowserInfo().name + ":" + this.getBrowserInfo().version;
          this.loading = true;
          params.loginEncryption = await getRsaCode(params.password)
          delete params.password          
          if (this.opts.loginType === "password") {
            userLogin(params)
              .then((res) => {
                if (res) {
                  this.reid=res.eid;
                  setStore("loginErrorCount", 0);
                  this.errorCount = 0;
                  this.$store.dispatch("toggleImageVerification", false);
                  console.log(res);
                  if (res) {
                    if (!cookies.getInClient("yqx_web_lang")) {
                      this.$store.commit("SET_LANG", "zh");
                    }
                    this.$store.commit("SET_TOKEN", res.token);
                    this.toOrg(res.eid);
                  } else {
                    this.loading = false;
                    this.getImgCode();
                    this.$message.error(res.msg);
                  }
                }
              })
              .catch(() => {
                this.loading = false;
                this.errorCount++;
                setStore("loginErrorCount", this.errorCount);
                this.getImgCode();
              });
          } else {
            LoginCode(params)
              .then((res) => {
                if (res) {
                  setStore("loginErrorCount", 0);
                  this.errorCount = 0;
                  this.$store.dispatch("toggleImageVerification", false);
                  if (!cookies.getInClient("yqx_web_lang")) {
                    this.$store.commit("SET_LANG", "zh");
                  }
                  this.$store.commit("SET_TOKEN", res.token);
                  this.toOrg();
                } else {
                  this.getImgCode();
                  this.$message.error(res.msg);
                }
              })
              .catch(() => {
                this.errorCount++;
                setStore("loginErrorCount", this.errorCount);
                this.getImgCode();
              });
          }
        }
      });
    },
    toOrg(eid) {
      if (this.eid || eid) {
        this.goEnterprise(this.eid || eid);
        return;
      }
      getUserEnterpriseList().then((data) => {
        if(!data) return
        data = data.filter((el) => !el["hasExpire"]);
        if (data.length > 0) {
          if (data.length > 1) {
            this.loading = false;
            setTimeout(() => {
              this.$router.push({ path: "/company" });
            }, 2000);
          } else {
            chooseEnterprise({
              eid: data[0].id,
            }).then((res) => {
              if (res) {
                this.loading = false;
                // this.$message.success({
                //   message: this.$t("loginSuccess"),
                //   showClose: true,
                //   duration: 2000,
                //   type: "success",
                // });
                this.$store.dispatch("SET_EID", data[0].id);
                this.$store.dispatch("GET_USERINFO");
                this.$store
                  .dispatch("GET_AUTHWEBINFO", { domain: window.location.host })
                  .then((res) => {
                    setStore("websiteInfo", {...res, vxPagetheme: (res.vxPagetheme || '#409EFF')});
                  });
                if (this.$route.query.redirect) {
                  this.$router.push({
                    path: decodeURIComponent(this.$route.query.redirect),
                  });
                } else {
                    // if (this.service.vxUseragreement) {
                    //   if(this.vxAgreementDisplayrules === 1) {
                    //       this.agressDialogVisible = true;
                    //   } else {
                    //     // 调用接口获取用户是否保存过用户协议 存在不弹出 不存在弹出 
                    //       getUserAgres().then((r)=> {
                    //         if(r === true) {
                    //           this.agressDialogVisible = false;
                    //           this.$router.push({ path: "/home" });
                    //         } else {
                    //           this.agressDialogVisible = true;
                    //         }
                    //       }).catch(() => {

                    //       })             
                    //   }
                    // } else {
                    //     this.$router.push({ path: "/home" });
                    // }
                    
                  this.$router.push({ path: "/home" });
                }
              }
            });
          }
        } else {
          this.$message.error(this.$t("noCompany"));
          this.loading = false;
        }
      });
    },
    goEnterprise(eid) {
      console.log("进入");
      chooseEnterprise({
        eid,
      }).then((res) => {
        if (res) {
          this.loading = false;
          // this.$message.success({
          //   message: this.$t("loginSuccess"),
          //   showClose: true,
          //   duration: 2000,
          //   type: "success",
          // });
          this.$store.dispatch("SET_EID", eid);
          this.$store.dispatch("GET_USERINFO");
          // if (this.service.vxUseragreement) {
          //   if(this.vxAgreementDisplayrules === 1) {
          //       this.agressDialogVisible = true;
          //   } else {
          //     // 调用接口获取用户是否保存过用户协议 存在不弹出 不存在弹出 
          //       getUserAgres().then((r)=> {
          //         if(r === true) {
          //           this.agressDialogVisible = false;
          //           this.$router.push({ path: "/home" });
          //         } else {
          //           this.agressDialogVisible = true;
          //         }
          //       }).catch(() => {

          //       })             
          //   }
          // } else {
          //   this.$router.push({ path: "/home" });
          // }
          this.$router.push({ path: "/home" });
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    handleChangeLoginType(type) {
      this.opts.loginType = type;
    },
    handleChangeTab(tab) {
      if (tab.paneName === "1") {
        this.getQrcode(this.$t("login.DingTalk"));
      } else if (tab.paneName === "2") {
        this.getQrcode(this.$t("login.EnterpriseWeChat"));
      } else if (tab.paneName === "4") {
        this.map.loginName = "";
      }
      this.$nextTick(() => {
        this.$refs.login.clearValidate();
      })
    },
    sendCode() {
      if (this.map.loginName) {
        const TIME_COUNT = 60;
        if (!this.opts.timer) {
          this.opts.count = TIME_COUNT;
          this.opts.timer = setInterval(() => {
            if (this.opts.count > 0 && this.opts.count <= TIME_COUNT) {
              this.opts.count--;
            } else {
              clearInterval(this.opts.timer);
              this.opts.timer = null;
            }
          }, 1000);
        }
        sendVerificationCode({
          phone: this.map.loginName,
          verificationCodeType: 2,
          eid: this.eid || null,
        })
          .then((res) => {
            if (res) {
              this.$message.success(res);
            }
          })
          .catch(() => {
            clearInterval(this.opts.timer);
            this.opts.count = 0;
            this.opts.timer = null;
          });
      } else {
        this.$message.error(this.$t("login.pleaseInputPhone"));
      }
    },
    getBrowserInfo: function () {
      /* eslint-disable */
      const Sys = {};
      const ua = navigator.userAgent.toLowerCase();
      let s;
      (s = ua.match(/rv:([\d.]+)\) like gecko/))
        ? (Sys.ie = s[1])
        : (s = ua.match(/msie ([\d]+)/))
        ? (Sys.ie = s[1])
        : (s = ua.match(/edge\/([\d]+)/))
        ? (Sys.edge = s[1])
        : (s = ua.match(/firefox\/([\d]+)/))
        ? (Sys.firefox = s[1])
        : (s = ua.match(/(?:opera|opr).([\d]+)/))
        ? (Sys.opera = s[1])
        : (s = ua.match(/chrome\/([\d]+)/))
        ? (Sys.chrome = s[1])
        : (s = ua.match(/version\/([\d]+).*safari/))
        ? (Sys.safari = s[1])
        : 0;
      // 根据关系进行判断
      if (Sys.ie) return { name: "IE", version: Sys.ie };
      if (Sys.edge) return { name: "EDGE", version: Sys.edge };
      if (Sys.firefox) return { name: "Firefox", version: Sys.firefox };
      if (Sys.chrome) return { name: "Chrome", version: Sys.chrome };
      if (Sys.opera) return { name: "Opera", version: Sys.opera };
      if (Sys.safari) return { name: "Safari", version: Sys.safari };
      return { name: "Unkonwn", version: "0.0.0" };
    },
    // 获取系统信息
    getOsInfo: function () {
      const userAgent = navigator.userAgent.toLowerCase();
      let name = "Unknown";
      let version = "Unknown";
      if (userAgent.indexOf("win") > -1) {
        name = "Windows";
        if (userAgent.indexOf("windows nt 5.0") > -1) {
          version = "Windows 2000";
        } else if (
          userAgent.indexOf("windows nt 5.1") > -1 ||
          userAgent.indexOf("windows nt 5.2") > -1
        ) {
          version = "Windows XP";
        } else if (userAgent.indexOf("windows nt 6.0") > -1) {
          version = "Windows Vista";
        } else if (
          userAgent.indexOf("windows nt 6.1") > -1 ||
          userAgent.indexOf("windows 7") > -1
        ) {
          version = "Windows 7";
        } else if (
          userAgent.indexOf("windows nt 6.2") > -1 ||
          userAgent.indexOf("windows 8") > -1
        ) {
          version = "Windows 8";
        } else if (userAgent.indexOf("windows nt 6.3") > -1) {
          version = "Windows 8.1";
        } else if (
          userAgent.indexOf("windows nt 6.2") > -1 ||
          userAgent.indexOf("windows nt 10.0") > -1
        ) {
          version = "Windows 10";
        } else {
          version = "Unknown";
        }
      } else if (userAgent.indexOf("iphone") > -1) {
        name = "Iphone";
      } else if (userAgent.indexOf("mac") > -1) {
        name = "Mac";
      } else if (
        userAgent.indexOf("x11") > -1 ||
        userAgent.indexOf("unix") > -1 ||
        userAgent.indexOf("sunname") > -1 ||
        userAgent.indexOf("bsd") > -1
      ) {
        name = "Unix";
      } else if (userAgent.indexOf("linux") > -1) {
        if (userAgent.indexOf("android") > -1) {
          name = "Android";
        } else {
          name = "Linux";
        }
      } else {
        name = "Unknown";
      }
      return {
        name,
        version,
      };
    },
    handleOpenUseLog(key) {
      this.$router.push({
        path: "agreement",
        query: {
          type: key,
        },
      });
    },
    getQrcode(text) {
      QRCode.toDataURL(text || "hello world!", {
        errorCorrectionLevel: "H",
        width: 160,
        height: 160,
      }).then((url) => {
        this.opts.codeUrl = url;
      });
    },
    // 提示联系管理员 TODO 是否要翻译
    tipRestMes() {
      this.$message.warning(this.$t("VX_login.contactManagerTip"));
    },
    // 拒绝协议后退出登录并清空登录数据
    refuseAgress() {
      saveUserAgres({agreementState: 0});
      setTimeout(() => {
        this.agressDialogVisible = false;
        this.$store.dispatch('REDIRECT_LOGIN');
      },0)
      
      // .then(() => {
      //       window.location.reload()
      // })
    },
    // 同意协议调用接口更新用户数据并跳转
    agreeAress() {
      // 调用保存接口
      saveUserAgres({agreementState: 1});
      setTimeout(() => {
        this.agressDialogVisible = false;
        this.$router.push({ path: "/home" });
      },0)
      
    },
    // 从企业信息中获取背景页面样式覆盖bgground_st
    getBackgroundCss() {
        var cssStr = this.service['vxPagecss'];
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = cssStr;
        document.head.appendChild(style);
    },
    setThemeColor(info) {
      
       if(info) {
          // 主题颜色
          let vxPagetheme = info.vxPagetheme;
          document.documentElement.style.setProperty('--el-color-primary', vxPagetheme);
       } 
       setTimeout(() => {
          this.loadColorFinish = true;
       },300)  
    }
  },
};
